import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {
    
    window.runScroll = function(el) {
        
        let offsetTop = document.querySelector(el).offsetTop;
        let topbar_height = 0;
  
        scroll({
            top: offsetTop-topbar_height,
            behavior: "smooth"
        })
    };

    const gtt = document.querySelectorAll("[data-target]");
    
    if (gtt.length > 0) {
        const action = function(e) {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;            
            runScroll(e.currentTarget.dataset.target);
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
