import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";


document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CSSRulePlugin);
    
    window.anims = function() {
        
        if (document.getElementsByClassName('a-hero').length > 0) {
            gsap.to('.a-hero', {
                scrollTrigger: {
                    trigger: ".a-hero",
                    start: "60%",
                    end: "bottom",
                    scrub: true,
                },      
                opacity: 0,
            });
        }
        
        if (document.getElementsByClassName('a-fadeInChildren').length > 0) {
            gsap.utils.toArray(".a-fadeInChildren > *").forEach(function(section) {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        toggleActions: "play complete complete reset",
                    },
                    autoAlpha: 0,
                    duration: 1,
                    y: 50
                });
            });
        }
        
        
        if (document.getElementsByClassName('a-stagger').length > 0) {
            gsap.set(".a-stagger", {y: 24, autoAlpha: 0});
            
            ScrollTrigger.batch(".a-stagger", {
                onEnter: batch => gsap.to(batch, {y: 0, stagger: 0.15, duration: .5, autoAlpha: 1}),
                onLeave: batch => gsap.to(batch, {opacity: 0, y: 24, duration: .5}),
                onEnterBack: batch => gsap.to(batch, { y: 0, stagger: 0.15, duration: .5, autoAlpha: 1 }),
                onLeaveBack: batch => gsap.to(batch, { y: 24, duration: .5, autoAlpha: 0 }),
                start: "top 100%",
                end: "bottom 20%",
            });
        }        
    };

}, false)
