import Swiper, { Autoplay, Navigation, Lazy } from 'swiper';
import 'swiper/swiper-bundle.css';

document.addEventListener('DOMContentLoaded', function() {
    
    const hero = document.getElementsByClassName('js-heroSwiper')[0];
    const brands = document.getElementsByClassName('js-brandsSwiper')[0];

    window.carousels = function() {
        let autoplay = 5000, i = 0, id;
        const bar = document.getElementsByClassName('js-progressbar')[0];

        const heroCarousel = function() {
    	    const swiper = new Swiper(hero, {
        	    modules: [Autoplay, Lazy, Navigation],
        	    autoplay: {
        	        delay: autoplay,
        	        disableOnInteraction: false,
    	        },
        	    navigation: {
                    nextEl: document.getElementsByClassName('js-hero')[0].getElementsByClassName('swiper-button-next')[0],
                    prevEl: document.getElementsByClassName('js-hero')[0].getElementsByClassName('swiper-button-prev')[0]
                },
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                },
                loop: true,
                on: {
                    slideChange: function() {
                        clearInterval(id);
                        move();
                    }
                },
                speed: 1000
    	    });
        };
        
        const brandsCarousel = function() {
            const carousel = brands;
            let status = false, swiper;
    
            const carousels = {
                destroy: function(el) {
                    swiper.destroy();
                },

                start: function(el) {    
                    swiper = new Swiper(carousel, {
                        modules: [Autoplay],
                        autoplay: {
                	        delay: 2500,
                	        disableOnInteraction: false,
            	        },
                        centeredSlides: true,
                        grabCursor: true,
                        loop: true,
                        speed:500,
                        slidesPerView: 'auto',

                        breakpoints: {
                            501: {
                                effect: "coverflow",
                                slidesPerView: 2,
                                coverflowEffect: {
                                    rotate: 0,
                                    stretch: 50,
                                    depth: 0,
                                    modifier: 1,
                                    slideShadows: false,
                                },
                            }
                        }
                    });
                },

                toggle: function() {
                    if (window.innerWidth <= 768) {
                        if (status === false) {     // in mobile, but carousel doesn't exists
    
                            carousels.start(carousel);
                            status = true;
    
                        } else {                    // in mobile, and carousel exists
    
                            carousels.destroy(carousel);
                            carousels.start(carousel);
                        }
    
                    } else {                        // browser bigger than 1024px
                        if (status === true) {
    
                            carousels.destroy(carousel);
                            console.log('destroy because windows is bigger than 1024');
                            status = false;
                        }
                    }
                }
            }
    
            carousels.toggle();
           // window.addEventListener('resize', carousels.toggle);
        };
        
        const move = function() {
            let width = 0, autoplayTime = (autoplay + 1000) / 100
            
            if (i === 0) {
                autoplayTime = autoplay / 100;
                i ++;
            }
            
            id = setInterval(frame, autoplayTime);
            
            function frame() {
                if (width >= 100) {
                    clearInterval(id);
                    bar.classList.remove('with-transition');
                } else {
                    width++; 
                    
                    if (width > 1) {
                        bar.classList.add('with-transition');
                    }
                    
                    bar.style.width = width + '%'; 
                }
            }
        };
        
        brands ? brandsCarousel() : false;
        hero ? heroCarousel() : false;
    }

}, false)
