import css from './sass/style.scss';

require('./js/anims.js');
require('./js/carousels.js');
require('./js/glightbox.js');
require('./js/init.js');
require('./js/lazy.js');
require('./js/map.js');
require('./js/nav.js');
require('./js/polyfills.js');
require('./js/scrollpos.js');
require('./js/smoothscroll.js');
require('./js/validate.js');
