import L from 'leaflet';
require('../../node_modules/leaflet/dist/leaflet.css');

document.addEventListener('DOMContentLoaded',function() {
    
    window.contactMap = function() {
        
        const map = document.getElementById('map');
        const coords = [54.6035625, 18.3485625];
        
        const mymap = L.map('map', {
            scrollWheelZoom: false
        }).setView(coords, 17);

        L.tileLayer('https://api.mapbox.com/styles/v1/bartoszm/ckslqs7kb67ad17r3lyphpm1k/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmFydG9zem0iLCJhIjoiY2tzbHFuaHQ1MTdtajJ2bDR6bTRyM2dnaCJ9.z0RMvwJEz-qhM9fqHlTvQg', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(mymap);

        // Start Simple marker
/*
        const myIcon = L.icon({
            iconUrl: map.dataset.defaultIcon,
            iconSize: [40, 48],
            iconAnchor: [40, 48],
            popupAnchor: [-3, -76],
        });   
*/     
        // End Simple marker
        
        // Start html marker
        const address = '<div class="c-map__address"><i class="icon-marker"></i> <p>ul. Gdańska 10</p><p>84-240 Reda</p></div>';
        const myIcon = L.divIcon({
            className: 'c-map__address',
            html: address,
        });
        // End html marker
        
        L.marker(coords, { 
            icon: myIcon
        }).addTo(mymap);
    };

}, false)